<template >    
    <v-card dense>
        <v-card-title>
            <s-toolbar color="primary" dark close save @close="close()" @save="save()"></s-toolbar>
        </v-card-title>
        <v-card-text>
            <div class="row">      
                <v-col lg="2">
                    <v-list dense>
                        <v-list-item dense>
                            <v-list-item-content>1. Seleccione Filas para Agrupar</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>2. Click en el Boton Agrupar</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>3. Elegir la Nueva Ubicacion del nuevo Pallet</v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>4. Guardar</v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>              
                <v-col class="col-md-4">
                    <s-textarea
                        label="Observaciones"
                        v-model="item.observations"
                    ></s-textarea>
                </v-col>
                <v-col lg="1">
                    <v-btn
                        fab
                        color="primary"
                        x-small                                        
                        depressed                        
                        @click="ShowViewDialogPalletized()">
                            <v-icon style="font-size: 16px !important" > fas fa-boxes</v-icon>
                    </v-btn>
                </v-col>
                
            </div>
            <div class="row">
                <div class="col-md-12">
                    <v-data-table
                        dense
                        :headers="headersPrePacking"
                        :items="PrePackingDetail"
                        disable-sort
                        v-model="SelectItemPicking"
                        
                        item-key="CpdID"
                  
                        >
                        <template v-slot:item="{ item }">
                            <tr @click="toggleSelection(item)" >
                                <td>
                                    <v-checkbox 
                                        v-model="item.selected"
                                        :disabled="item.disabled"
                                        @click.stop>

                                    </v-checkbox>
                                </td>
                                <td>
                                    {{ item.PalletGroupID }}
                                </td>
                                <td>
                                    {{ item.TraceabilityID }}
                                </td>
                                <td>
                                    {{ item.TypeCultiveName }}
                                </td>
                                <td>
                                    {{ item.VrtDescription }}
                                </td>
                                <td>
                                    {{ item.TypePackingName }}
                                </td>
                                <td>
                                    {{ item.TypeCutName }}
                                </td>
                                <td>
                                    <v-chip x-small color="success">
                                        {{ item.PalletLocation }}
                                    </v-chip>
                                </td>                               
                                <td>
                                    <v-chip x-small color="info">
                                        {{ item.TotalBoxAvailable }}
                                    </v-chip>
                                </td>
                                <td>
                                    <v-chip x-small color="success">
                                        {{ item.TotalBagWeight }}
                                    </v-chip>
                                </td>
                                <td>
                                    <v-chip x-small color="info">
                                        {{ item.TotalBagWeightAvailable }}
                                    </v-chip>
                                </td>
                                <td>
                                    {{ item.StateFinalString }}
                                </td>
                            </tr>
                        </template>
                        <!-- <template v-slot:item.TotalBox="{ item }">                            
                            
                        </template>
                        <template v-slot:item.TotalBoxAvailable="{ item }">                            
                            
                        </template>
                        <template v-slot:item.TotalBagWeight="{ item }">                            
                           
                        </template>
                        <template v-slot:item.TotalBagWeightAvailable="{ item }">                            
                            
                        </template> -->
                    </v-data-table>
                </div>
            </div>
            <v-dialog v-model="ShowDialogPalletized"
               
                fullscreen>
                <chamber-paletized :SelectItemPicking="SelectItemPicking" @closePaletized="closePaletized"></chamber-paletized>
            </v-dialog>
        </v-card-text>
    </v-card>
    
</template>

<script>
    import _sPicking from "@/services/FrozenProduction/FrzChamberPicking.js";
    import ChamberPaletized from "./FrzChamberPaletized.vue";
    export default{
        components:{
            ChamberPaletized
        },
        props:{
            SelectPrePacking:{
                default: null,
                type:Object,
            }
        },
        data(){
            return{
                
                headersPrePacking: [
                    { text: 'Fila', value: 'selected', sortable: false   },
                    { text: "ID PALLET", value: "PalletGroupID" },
                    { text: "CÓDIGO DE PRODUCTO", value: "TraceabilityID" },
                    { text: "FRUTA", value: "TypeCultiveName" },
                    { text: "VARIEDAD", value: "TypeCultiveName" },
                    { text: "EMPAQUE", value: "TypePackingName" },
                    { text: "CORTE", value: "TypeCutName" },
                    { text: "UBICACION", value: "PalletLocation" },
                    { text: "N° CAJAS", value: "TotalBox", align: "center"},
                   
                    { text: "PESO", value: "TotalBagWeight",  align: "center" },
                    { text: "PESO", value: "TotalBagWeightAvailable", align: "center" },
                   
                    { text: "ESTADO", value: "StateFinalString" },
                    { text: "DETALLE", value: "reasonChange" },
                    // { text: "FECHA DE VENCIMIENTO", value: "Fecha", width: 20 },
                    // { text: "OBSERVACIONES (Marca/Presentacion/Codigo SAP)", value: "Observaciones", width: 20 },
                    // { text: "Ubicacion", value: "Observaciones", width: 20 },
                    // { text: "Opcion", value: "Observaciones", width: 20 },
                    // { text: "EMPAQUE", value: "TypePackingName", width: 20 },
                ],
                PrePackingDetail: [],
                SelectItemPicking: [],
                item: {},
                ShowDialogPalletized: false,
            }
        },
        watch: 
        {
            SelectPrePacking(){
                this.PrePackingDetail = this.SelectPrePacking.ListPackingDetails;      
                       
            }
        },
        created(){            
            this.PrePackingDetail = this.SelectPrePacking.ListPackingDetails;    
            this.SelectItemPicking = this.PrePackingDetail.filter(objeto => objeto.selected === true);
            
        }
        ,methods:
        {
            
            closePaletized(updateItems){
                
                if(updateItems == undefined)
                {
                    this.ShowDialogPalletized = false
                    return
                }
                    
                updateItems.forEach(updateItem => {
                    const index = this.PrePackingDetail.findIndex(item => item.CpdID === updateItem.CpdID);
                    if(index !== -1){
                        this.PrePackingDetail.splice(index, 1, updateItem);
                    }
                })
                this.ShowDialogPalletized = false
            },
            toggleSelection(item)
            {                
                if(!item.disabled){
                    item.selected = !item.selected;
                    if(item.selected)
                    {
                        this.SelectItemPicking.push({ ...item})
                    }else{
                        const index = this.SelectItemPicking.findIndex(row => row.CpdID === item.CpdID);
                        if(index !== -1)
                        {
                            this.SelectItemPicking.splice(index, 1);
                        }
                    }
                }
            },
            
            ShowViewDialogPalletized()
            {                
                if(this.SelectItemPicking.length > 0)
                {
                    this.ShowDialogPalletized = true;
                }else{
                    this.$fun.alert("Seleccione items ", "warning");
                }
            },
            close()
            {
                this.$emit("closeDialog");
            },
            save(){

                
                // if(this.SelectItemPicking.length == 0)
                // {
                //     this.$fun.alert("Seleccione los items", "warning");
                //     return;
                // }
                // if(this.SelectItemPicking.length != this.PrePackingDetail.length)
                // {
                //     this.$fun.alert("La lista del Picking no coincide con la lista del PrePacking", "warning");
                //     return;
                // }
                // this.item.UserID = this.$fun.getUserID();
                // this.item.CppID = this.SelectPrePacking.CppID;
                // this.item.CumID = this.SelectPrePacking.CumID;
                // this.item.TypeCultive = this.SelectPrePacking.TypeCultive;
                // this.item.TypeCrop = this.SelectPrePacking.TypeCrop;
                // this.item.TypeCut = this.SelectPrePacking.TypeCut;
                // this.item.TypePacking = this.SelectPrePacking.TypePacking;
                // this.item.pickingDetails = this.SelectItemPicking;
                // let QuantityBoxes = 0;
                // let QuantityBoxesWeight = 0;
                this.SelectItemPicking.forEach(element => {
                    // QuantityBoxes = QuantityBoxes + parseInt(element.TotalBox);
                    // QuantityBoxesWeight = QuantityBoxesWeight + parseFloat(element.TotalBagWeight);
                    element.IsPicking = 1;
                })
                // this.item.QuantityBoxes = QuantityBoxes;
                // this.item.QuantityBoxesWeight = QuantityBoxesWeight;                
                this.$fun.alert("Desea registrar Picking", "question").then( r => {
                    if(r.value)
                    {
                        _sPicking.save(this.SelectItemPicking, this.$fun.getUserID()).then(r => {
                            if(r.status == 200){
                                this.$fun.alert("Se registro correctamente", "success");
                                this.$emit("closeDialog");
                            }
                        })
                    }
                    
                })
                
            }
        }
    }
</script>
<style>
.disabled-row {
  /* Estilos para una fila deshabilitada */
  opacity: 0.5; /* Por ejemplo, reducir la opacidad */
  pointer-events: none; /* Evitar interacciones con la fila */
}
</style>