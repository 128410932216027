<template>
    <div class="container">
        <v-card>
            <v-card-title class="title font-weight-light">
                <s-toolbar  color="primary" dark  label="Picking">

                </s-toolbar>
            </v-card-title>            
            <v-card-text>
                <div class="row" justify="center">                   
                    <v-col  cols="auto" >
                        <s-select-client
                            clearable
                            label="Cliente(es):"
                            v-model="filter.CumID"
                            ref="txtCumID"
                            full 
                             @input="searchitemsSend($event)"
                            >
                        </s-select-client>      
                    </v-col>
                    <v-col cols="auto">                              
                        <s-select-definition
                            clearable
                            label="Tipo corte"
                            :def="1429"
                            v-model="filter.TypeCut"
                             @input="searchitemsSend($event)"
                        ></s-select-definition>    
                    </v-col>
                    <v-col cols="auto">
                        <s-select-definition
                            clearable
                            label="Tipo Empaque"
                            v-model="filter.TypePacking"
                            :def="1435"
                             @input="searchitemsSend($event)"
                        ></s-select-definition>
                    </v-col>
                    <v-col cols="auto">
                        <s-date label="Fecha Inicio"
                            v-model="DateBegin"
                            @input="searchitemsSend($event)"
                            >
                        </s-date>
                    </v-col>
                    <v-col cols="auto">
                        <s-date label="Fecha Fin"
                            v-model="DateEnd"
                            @input="searchitemsSend($event)"
                            >
                        </s-date>
                    </v-col>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <v-data-table
                            dense
                            :headers="headersPrePacking"
                            :items="itemsPrePacking"
                            :items-per-page="-1"
                            disable-sort
                            v-model="PrePackingListDispatch"
                            
                            item-key="CppID"
                            >
                            <template v-slot:item.picking="{ item }">
                                <v-btn	
                                    v-if="item.IsPicking != 1"	
                                    elevation="2"					
                                    color="success"
                                    fab
                                    x-small                                        
                                    depressed
                                    @click="PickingPrePacking(item)"
                                >
                                    <v-icon class="mdi-24px">mdi-qrcode-scan</v-icon>
                                </v-btn> 
                                <v-btn
                                    v-if="item.IsPicking == 1 && item.IsDispatch ==  null"
                                    fab
                                    color="#23BAC4"
                                    x-small                                        
                                    depressed
                                    @click="ShowdialogViewDetail(item)">
                                    <v-icon class="mdi-light mdi-24px">mdi-file-find</v-icon>
                                 </v-btn>
                            <!--  <v-btn
                                    v-if="item.IsDispatch == 1 || item.IsDispatch == 2"
                                    fab
                                    color="green"
                                    x-small                                        
                                    depressed
                                    @click="ShowViewdialogDispatch(item)">
                                    <v-icon  class="mdi-24px mdi-light">mdi-printer</v-icon>
                                </v-btn> -->
                            </template>
                           
                        </v-data-table>
                    </div>
                </div>
            </v-card-text>
        </v-card>
        <v-dialog
            v-model="dialogPrePackingDetail"
            v-if="dialogPrePackingDetail"
            persistent
            fullscreen            
            hide-overlay
            transition="dialog-bottom-transition"
            
        >
            <chamber-picking :SelectPrePacking="SelectPrePacking" @closeDialog="closePackingDetail()"></chamber-picking>
        </v-dialog>
        <v-dialog 
            v-model="dialogDispatch"
            v-if="dialogDispatch"
            fullscreen
            transition="dialog-bottom-transition"
            persistent>
            <packing-dispatch :SelectPrePacking="SelectPrePacking" @closedialogDispatch="closedialogDispatch()"></packing-dispatch>
        </v-dialog>
        <v-dialog v-model="dialogPrintPdf"
            v-if="dialogPrintPdf"            
            transition="dialog-bottom-transition">
                <dispatch-pdf @closedialogPrintPdf="closedialogPrintPdf()" :PrePackingPdf="PrePackingPdf"></dispatch-pdf>
        </v-dialog>
        <v-dialog v-if="dialogPrePackingView"
            v-model="dialogPrePackingView"
            persistent 
            fullscreen>
                <view-detail :SelectPrePacking="SelectPrePacking" @closeDialog="dialogPrePackingView = false"></view-detail>
        </v-dialog>
    </div>
</template>

<script>
    import DispatchPdf from "./FrzChamberDispatchPdf.vue";
    import ChamberPicking from "./FrzChamberPickingDetail.vue";
    import PackingDispatch from "./FrzChamberPrePackingDispatch";
    import ViewDetail from "./FrzChamberPickingViewDetail.vue"
    import _sPrePacking from "@/services/FrozenProduction/FrzChamberPrePacking.js";

    import SSelectClient from "@/components/FrozenProduction/Maturation/SSelectClienteMaturation";
    export default {
        components:{ChamberPicking, PackingDispatch, DispatchPdf, ViewDetail, SSelectClient},
        data(){
            return {
                headersPrePacking: [
                    { text: "Picking", value: "picking", width: 20 },  
                    { text: "CLIENTE", value: "CumName", width: 20 },
                    { text: "TRAZABILIDAD", value: "TraceabilityIDs", width: 20 },
                    { text: "CORRELATIVO", value: "CppCorrelative", width: 20 },                    
                    { text: "FECHA", value: "CppDateCreateString", width: 20 },
                    { text: "CLIENTE", value: "CumName", width: 20 },
                    { text: "CAJAS", value: "NumberBox", width: 20 },                    
                    { text: "CULTIVO", value: "TypeCultiveName", width: 20 },
                    { text: "VARIEDAD", value: "VrtDescription", width: 20 },
                    { text: "CORTE", value: "TypeCutName", width: 20 },
                    { text: "EMPAQUE", value: "TypePackingName", width: 20 },
                    { text: "N° VERSION", value: "versionnumber", width: 20 },
                    { text: "EMBARQUE", value: "numberShipment", width: 20 },
                    
                ],
                itemsPrePacking: [],
                SelectPrePacking: {},
                dialogPrePackingDetail: false,
                DateBegin: "",
                DateEnd: "",
                PrePackingListDispatch: [],
                dialogDispatch: false,
                dialogPrintPdf: false,
                PrePackingPdf: {},
                dialogPrePackingView: false,
                CumID: 0,
                filter: {}
            }
        },
        methods:{
            initialize(){
                if(this.DateBegin == "" || this.DateEnd == ""){
					return;
				}
                let filter = {
                    DateBegin: this.DateBegin, 
                    DateEnd: this.DateEnd,
                    CumID: this.filter.CumID,
                    TypePacking : this.filter.TypePacking,
                    TypeCut : this.filter.TypeCut,
                }
                _sPrePacking.listpicking(filter, this.$fun.getUserID()).then(r => {
                    if(r.status == 200){
                       
                        if(r.data.length > 0)
                        {
                            r.data[0].ListPackingDetails.forEach(element => {                            
                                if(element.SendGroup)
                                {
                                    element.disabled = true;
                                    element.selected = true;
                                }else{
                                    element.disabled = false;
                                    element.selected = false;
                                }
                                
                            })     

                            this.itemsPrePacking = r.data; 
                            
                        }else
                        {
                            this.itemsPrePacking = [];
                        }      
                    }
                })
            },
            PickingPrePacking(item)
            {                
                
                this.SelectPrePacking = item;
                this.dialogPrePackingDetail = true;
            },
            ShowdialogViewDetail(item)
            {
                this.SelectPrePacking = item;
                this.dialogPrePackingView = true;
            },
            searchitemsSend(event)
            {
                this.initialize();
            },    
            closePackingDetail()
            {
                this.dialogPrePackingDetail = false;
                this.initialize();
            },
            ShowdialogDispatch(item){
                this.dialogDispatch = true;
                this.SelectPrePacking = item;          
                
            },
            ShowViewdialogDispatch(item){
                this.dialogPrintPdf = true;
                this.PrePackingPdf = item;
                
            },
            closedialogDispatch()
            {
                this.dialogDispatch = false;
                this.initialize();
            },
            closedialogPrintPdf()
            {
                this.dialogPrintPdf = false;
                this.initialize();
            }
        },
        created(){
            
        },
        mounted(){
            this.initialize();
        }
    }
</script>