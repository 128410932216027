import Service from "../Service";
const resource = "chamberpicking/";

export default {
  

    pagination(parameters, requestID) {        
        return Service.post(resource + "pagination", parameters, {
            params: { requestID: requestID },
        });
    },

    save(abt, requestID){
        return Service.post(resource + "save", abt,{
            params: { requestID: requestID }
        });
    },
    
   list(fcb, requestID){
        return Service.post(resource + "list", fcb,{
            params: { requestID: requestID }
        });
    },

    getdetail(fcb, requestID)
    {
        return Service.post(resource + "getdetaillist", fcb,{
            params: { requestID: requestID }
        });
    },

    Dispatched(fcb, requestID)
    {
        return Service.post(resource + "getdispatched", fcb,{
            params: { requestID: requestID }
        });
    }

}