<template>
    <v-card dense>
        <v-card-title>
            <s-toolbar label="Unir Pallets y agregar Ubicacion" color="primary" dark close @close="close()"></s-toolbar>
        </v-card-title>
        <v-card-text>
            <v-row>
                <v-col cols="auto" v-for="(item,index) in SelectItemPicking" :key="index">
                    <v-card  outlined  color="#EDF2FA" theme="dark">
                        <!-- <v-radio-group v-model="PalletSelect" style="margin-top: 15px; margin-right: 9px 10px;"  >
                            <v-radio  :value="item" :label=item.PalletGroupID.toString()>

                            </v-radio>
                        </v-radio-group> -->
                        <v-card-title>
                            <v-toolbar class="flex-grow-4" dark color="#5edf87" height="30"  rounded >
                                <v-toolbar-title>{{   item.TraceabilityID }}</v-toolbar-title>
                            </v-toolbar>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <b>Ubicacion:</b>{{ ' ' + item.PalletLocation }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <b>N° Cajas:</b>{{ ' '+ item.TotalBox }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <b>Peso:</b> {{ ' '+ item.TotalBagWeightAvailable }}
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <b>Cultivo:</b>{{ ' '+ item.TypeCultiveName }}
                                </v-col>
                            </v-row>
                            <!-- <v-row>
                                <v-col>
                                    <b>Tipo:</b>{{ ' '+ item.VrtName }}
                                </v-col>
                            </v-row> -->
                        </v-card-text>
                        <v-card-actions>
                            <!-- <v-row>
                                <v-col>
                                    <s-text @input="txtCantidad(item.cantidad)"  v-model="item.cantidad"></s-text>
                                </v-col>
                            </v-row> -->

                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>  
            <v-row>
                <v-col>
                    <s-select
                        :items="listChamber"
                        label="Camaras"
                        placeholder="Digite Usuario"
                        item-text="CdtDescription"
                        item-value="CdtID"
                        v-model="SelectChamber"
                        return-object      
                        @input="click($event)"                              
                        >
                    </s-select>
                </v-col>
                <v-col>
                    <s-select
                        :items="SelectChamber.tunnelsDetails"
                        label="Lado"
                        placeholder="Digite Usuario"
                        item-text="SideDescription"
                        item-value="CddID"
                        v-model="SelectSide"
                        return-object 
                        @input="clickSide($event)"                                   
                        >
                    </s-select>
                </v-col>
                <v-col cols="auto">
                    <s-select 
                        clearable
                        label="Carril"
                        item-text="Name"
                        item-value="Index"
                        :items="carriles"
                        v-model="FilterCarril"
                        @input="filterPallets()">

                    </s-select>
                </v-col>
            </v-row>     
            <v-row>

                <v-col cols="auto" v-for="(item, indexNumberLanes) in SelectSide.NumberLanes" 
                                            :key="indexNumberLanes" :value="item" >
                    <v-card theme="dark" hover >
                        
                        <p hidden v-if="FilterCarril > 0" > {{ indexNumberLanes = FilterCarril - 1 }}</p>
                                <v-toolbar class="flex-grow-0" dark color="#5edf87" height="30"  rounded >
                                    <v-toolbar-title>{{ 'Carril ' +  parseInt(item + SelectSide.NumberStart)}}</v-toolbar-title>
                                </v-toolbar>
                            
                            <v-card-text>
                                <v-row dense v-for="(nivel, indexNumberLevels) in SelectSide.NumberLevels" 
                                                v-bind:key="indexNumberLevels" :value="nivel">
                                    <v-col  cols="auto"  class="py-1 pe-0">
                                        <v-card hover color="#f4edeb" >
                                            <v-toolbar color="#299ff9" height="30" shaped >
                                                <v-toolbar-title>{{ Position[indexNumberLevels] }}</v-toolbar-title>
                                            </v-toolbar>
                                            
                                            <v-row class="mt-1" dense>
                                                <v-col cols="auto"  v-for="(posicion, indexPositionsLevel) in SelectSide.PositionsLevel" 
                                                    v-bind:key="indexPositionsLevel" :value="posicion">
                                                    <v-card  v-if="SelectChamber.Pallest?.filter((x) =>  
                                                                        x.SideID == SelectSide.SideID
                                                                        && x.NumberLanes === indexNumberLanes 
                                                                        && x.NumberLevels === indexNumberLevels
                                                                        && x.PositionsLeve === indexPositionsLevel).length == 1"
                                                        disabled
                                                        color="red accent-2" 
                                                        hover                                                     
                                                        >
                                                        <v-card-text>
                                                            {{  posicion }}                                                                        
                                                        </v-card-text>
                                                    </v-card>

                                                    <v-card  v-else                                                        
                                                        :color="indexNumberLanes == selectNumberLane
                                                                && indexNumberLevels == selectNumberLevel 
                                                                && indexPositionsLevel == selectPositionsLevel ? '#7bc6fe' : '#f0faf7'" 
                                                        hover 
                                                        @click="Probar(indexNumberLanes, indexNumberLevels, indexPositionsLevel)"
                                                        >
                                                        <v-card-text>
                                                            {{  posicion }}                                                                        
                                                        </v-card-text>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        <!-- </v-container> -->
                    </v-card>                            
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn  
                
                elevation="3"
				small  color="primary" class="btn-flotante"                                     
                depressed
                @click="Paletizar()">
                    <v-icon style="font-size: 16px !important" > fas fa-boxes</v-icon>Agrupar Pallets
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import _sPrePacking from "@/services/FrozenProduction/FrzChamberPrePacking.js";
    import _sChamber from '@/services/FrozenProduction/FrzChamberDispatchTunnelsChamber.js';
    export default{
        watch:{
            // PalletSelect(){
            //     this.CalcularTotalCajas();
            // }
        },
        props: {
            SelectItemPicking:{
                default: null,
                type:Array,
            }
        },
        data(){
            return{
                PalletSelect: {},
                PalletPaletized: [],
                btnDisabled: true,
                TotalBoxPaletized: 0,
                totalagregar: 0,

                listChamber:[],
                SelectChamber: {},
                SelectSide: {},
                Position: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'],
                selectNumberLane: null,
                selectNumberLevel: null,
                selectPositionsLevel: null,
                carriles: [],
                FilterCarril: 0,
            }
        },
        methods:{
            initialize(){
                _sChamber.list({CdtWarehouses: 1}, this.$fun.getUserID()).then((r) => {
                    if(r.status = 200)
                    {
                        this.listChamber = r.data;                        
                    }
                });
            },
            Probar(item, nivel, posicion){                
                this.selectNumberLane = item;
                this.selectNumberLevel = nivel;
                this.selectPositionsLevel = posicion;
            },
            txtCantidad(value)
            {
                // this.totalagregar = 0;
                // this.SelectItemPicking.forEach(element => {
                //     if(element.CpdID != this.PalletSelect.CpdID)
                //     {                        
                //         if(element.cantidad > 0){
                //             this.totalagregar =  parseInt(this.totalagregar) + parseInt(element.cantidad); 
                //         }
                //     }
                // });

                // console.log(this.totalagregar , this.TotalBoxPaletized);
                // // this.totalagregar =  parseInt(this.totalagregar) + parseInt(value); 
                // if(this.totalagregar == this.TotalBoxPaletized)
                // {
                //     this.btnDisabled = false;
                // }else{
                //     this.btnDisabled = true;
                // }
            },
            close(){
                this.$emit("closePaletized");
            },
            filterPallets()
            {                     
                if(this.FilterCarril > 0 && this.FilterCarril != null)
                {         
                    this.isShow = true;
                    this.isFilter = false;
                    this.SelectSide.NumberLanes = 1;
                    this.PaletsFilters = [];
                    this.SelectSide.NumberStart = this.FilterCarril - 1;
                }

                if (this.FilterCarril == 0 || this.FilterCarril == null)
                {                      
                    this.SelectSide = {};       
                    this.initialize();  
                }     
            },
            click(SelectChamber){
                this.SelectSide = {};

            },
            clickSide(side){
               
               this.FilterCarril = 0;
               this.carriles = [];
               this.SelectSide = side;
               for(let i = side.NumberStart + 1; i <= side.NumberLanes + side.NumberStart; i++)
               {
                   let carril = {}
                   carril.Index = i;
                   carril.Name = 'Carril ' + i;
                   this.carriles.push(carril);
               }               
           },
            Paletizar()
            {
                let NewPallet = {};
                let numberBox = 0;
                let WeightNetBox = 0;
                let PalletGroupIDS = "";
                let CppID = 0;

                    this.SelectItemPicking.forEach(element => {
                        console.log(element);
                        numberBox = numberBox + parseInt(element.TotalBox);
                        WeightNetBox = WeightNetBox + element.TotalBagWeightAvailable;
                      
                        if(!PalletGroupIDS.includes(element.PalletGroupID))
                        {
                            PalletGroupIDS = PalletGroupIDS + element.PalletGroupID + ",";
                        }
                        element.UsrCreateID = this.$fun.getUserID();
                        element.TotalBagWeight = element.TotalBagWeightAvailable;
                        CppID = element.CppID;
                    });
                    NewPallet.CdtID = this.SelectChamber.CdtID;
                    NewPallet.CppID = CppID;
                    if(NewPallet.CdtID == undefined)
                    {
                        this.$fun.alert("Por Favor Seleccione Camara ", "warning");
                        return;
                    }

                    if(this.SelectSide.SideID == undefined)
                    {
                        this.$fun.alert("Por Favor Seleccione Lado ", "warning");
                        return;
                    }
                    
                    if(this.selectNumberLane == null)
                    {
                        this.$fun.alert("Por Favor Seleccione un Carril ", "warning");
                        return;
                    }
                    if(this.selectNumberLevel == null)
                    {
                        this.$fun.alert("Por Favor Seleccione un Nivel ", "warning");
                        return;
                    }
                    if(this.selectPositionsLevel == null)
                    {
                        this.$fun.alert("Por Favor Seleccione una Posicion ", "warning");
                        return;
                    }
                    NewPallet.SideID = this.SelectSide.SideID;
                    NewPallet.NumberLanes = this.selectNumberLane;
                    NewPallet.NumberLevels = this.selectNumberLevel;
                    NewPallet.PositionsLeve = this.selectPositionsLevel;
                    NewPallet.UsrCreateID = this.$fun.getUserID();

                    NewPallet.PalletGroupIDS = PalletGroupIDS.substring(0, PalletGroupIDS.length - 1);
                    NewPallet.NumberBoxes = numberBox;
                    NewPallet.WeightNetBoxes = WeightNetBox

                    let chambername = "";
                    chambername = this.SelectChamber.CdtDescription;  
                    if(chambername.length > 0)              
                    NewPallet.PalletLocation = (chambername.substring(0, 3) + ' ' + chambername.slice(-1) + ' ' + 'CARRIL ' + parseInt(this.selectNumberLane + 1 + this.SelectSide.NumberStart)+ '/' +  this.Position[this.selectNumberLevel] + parseInt(this.selectPositionsLevel + 1 ));
                    NewPallet.palletDetailCEs = this.SelectItemPicking;
                    this.SelectItemPicking.forEach(r => {
                                r.disabled = true;
                            });

                    const updateItems = this.SelectItemPicking.map(item => ({ ...item}));
                    this.$fun.alert("Desea registrar Paletizado", "question").then((r) => {
                        if(r.value)
                        {
                            _sPrePacking.agrupar(NewPallet, this.$fun.getUserID()).then(r => {
                            if(r.status == 200)
                                {
                                    this.$fun.alert("Se registro correctamente", "success");
                                    
                                    this.$emit("closePaletized", updateItems );
                                }
                            
                            })
                        }                    
                    })

                

                
            },
            CalcularTotalCajas()
            {
                // this.totalagregar = 0;
                // this.TotalBoxPaletized = this.PalletSelect.TotalBox;
                // this.SelectItemPicking.forEach(element => {
                //     if(element.CpdID != this.PalletSelect.CpdID)
                //     {                 
                //         let cantidad = parseInt(this.PalletSelect.TotalBox) / parseInt(this.SelectItemPicking.length - 1);    
                //         let pesounidad =  this.PalletSelect.TotalBagWeight / this.PalletSelect.TotalBox;
                //         console.log(pesounidad);             

                //         if(Number.isInteger(cantidad))
                //         {
                //             element.cantidad = cantidad;
                //             let pallet = {};
                //             pallet.CpdID = this.PalletSelect.CpdID; // ID PALLET A DECOSCOMPONER
                //             pallet.TotalBox = element.cantidad;
                //             pallet.TotalBagWeight = pesounidad * element.cantidad;
                //             pallet.CpdIDadd = element.CpdID; // ID PALLET A AGREGAR
                //             this.PalletPaletized.push(pallet);
                //             this.btnDisabled = false;
                //         }   
                //         else{
                //             this.$fun.alert("la paletizacion se debe realizar manual", "info");   
                //             element.cantidad = '';  
                //             this.btnDisabled = true;                       
                //         }                                    
                        
                //     }
                //     else{
                //         element.cantidad = '';
                //     }

                // })
            }
        },
        created(){
            this.initialize();
        }
    }
</script>

<style>
.btn-flotante {
	font-size: 16px; /* Cambiar el tamaño de la tipografia */
	text-transform: uppercase; /* Texto en mayusculas */
	font-weight: bold; /* Fuente en negrita o bold */
	color: #ffffff; /* Color del texto */
	border-radius: 5px; /* Borde del boton */
	letter-spacing: 2px; /* Espacio entre letras */
	background-color: #E91E63; /* Color de fondo */
	padding: 18px 30px; /* Relleno del boton */
	position: fixed;
	bottom: 40px;
	right: 40px;
	transition: all 300ms ease 0ms;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	z-index: 99;
}
.btn-flotante:hover {
	background-color: #2c2fa5; /* Color de fondo al pasar el cursor */
	box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.3);
	transform: translateY(-7px);
}
</style>