<template>
    
        <v-card>
            <v-card-title>
                <s-toolbar color="primary" dark close @close="close()" ></s-toolbar>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col style="font-weight: bold;text-decoration: underline;">PalletGroupID </v-col>
                    <v-col style="font-weight: bold;text-decoration: underline;">Trazabilidad</v-col>
                    <v-col style="font-weight: bold;text-decoration: underline;">Cliente</v-col>
                    <v-col style="font-weight: bold;text-decoration: underline;">Fruta</v-col>
                    <v-col style="font-weight: bold;text-decoration: underline;">Corte</v-col>
                    <v-col style="font-weight: bold;text-decoration: underline;">Presentacion</v-col>
                    <v-col style="font-weight: bold;text-decoration: underline;">Total Cajas</v-col>
                    <v-col style="font-weight: bold;text-decoration: underline;">Total Peso</v-col>                    
                    <v-col style="font-weight: bold;text-decoration: underline;">Estado Final</v-col>
                </v-row>
                <v-row v-for="(item, index) in resumen" :key="index">
                    <v-col>{{ item.PalletGroupID }}</v-col>
                    <v-col>{{ item.TraceabilityID }}</v-col>
                    <v-col>{{ item.CumName }}</v-col>
                    <v-col>{{ item.TypeCultiveName }}</v-col>
                    <v-col>{{ item.TypeCutName }}</v-col>
                    <v-col>{{ item.TypePresentationName }}</v-col>
                    <v-col>{{ item.TotalBox }}</v-col>
                    <v-col>{{ item.TotalBagWeight }}</v-col>                    
                    <v-col>{{ item.StateFinalString }}</v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>

            </v-card-actions>
        </v-card>
  
</template>

<script>
import _sPicking from "@/services/FrozenProduction/FrzChamberPicking.js";
export default {
    props:{
        SelectPrePacking: {
                default: null,
                type:Object,
            }
        },
    data(){
        return {
            detalles: [],
            resumen: [],
        }
    },
    methods:{
        initialized(){
            _sPicking.getdetail(this.SelectPrePacking, this.$fun.getUserID()).then(r => {
                if(r.status == 200){
                    

                  
                    let PalletGroupID = 0;
                    let num = 0;
                    
                    this.detalles = r.data;
                    this.detalles.ListIncomeDetails.forEach(element => {
                        
                        console.log('data', element);
                        if(PalletGroupID == element.PalletGroupID)
                        {
                            this.resumen[num].TotalBagWeight += element.TotalBagWeight;
                            this.resumen[num].TotalBox += element.TotalBox;
                            this.resumen[num].TraceabilityID += ', '+ element.TraceabilityID;
                        }else
                        {
                            PalletGroupID = element.PalletGroupID;
                            this.resumen.push(element);
                            if(this.resumen.length > 1)
                            {
                                num++;
                            }
                            
                        }
                    });

                    
                }
            })
        },
        close()
        {
            this.$emit("closeDialog");
        },
    },
    created(){
        
        this.initialized();
    }
}
</script>